import { authClient } from "~/libs/auth-client";
import { LoginLayout } from "~/components/layout/login-layout";
import { Label } from "~/components/ui/label";
import { Input } from "~/components/ui/input";
import React, { useState } from "react";
import { Button } from "~/components/ui/button";
import Link from "next/link";
import { z } from "~/libs/z-i18n";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "~/components/ui/form";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import nextI18NextConfig from "next-i18next.config.cjs";
import { useRouter } from "next/router";
import { auth } from "~/server/libs/auth";
import type { IncomingMessage } from "node:http";
import { InputPassword } from "~/components/ui/input-password";

const formSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
});

export default function Login() {
  const { t } = useTranslation("login");
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    if (isLoading) {
      return;
    }
    void (async () => {
      setIsLoading(true);
      const result = await authClient.signIn.email({
        email: data.email,
        password: data.password,
        rememberMe: true,
      });
      if (result.error?.code) {
        form.setError("password", {
          message: t(`form.error.${result.error.code}`),
        });
        setIsLoading(false);
      } else {
        await router.push("/app/");
      }
    })();
  };

  return (
    <LoginLayout>
      <div>
        <h2 className={"font-500 mb-[40px] mt-[60px] text-[28px] text-black"}>
          {t("title")}
        </h2>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <div>
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="p-0">
                    <FormControl>
                      <fieldset className="w-full">
                        <Label htmlFor="input-email" className={"text-black"}>
                          {t("form.email.label")}
                        </Label>
                        <Input
                          {...field}
                          type={"email"}
                          id={"input-email"}
                          dimension="xl"
                          className="w-full"
                          placeholder={t("form.email.placeholder")}
                          variant="bordered"
                        />
                      </fieldset>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="p-0">
                    <FormControl>
                      <fieldset className="w-full">
                        <Label
                          htmlFor="input-password"
                          className={"text-black"}
                        >
                          {t("form.password.label")}
                        </Label>
                        <InputPassword
                          {...field}
                          id={"input-password"}
                          dimension="xl"
                          className="w-full"
                          placeholder={t("form.password.placeholder")}
                          variant="bordered"
                        />
                      </fieldset>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div>
              <Link className="text-black underline" href={"/forgot-password"}>
                {t("forgotlink")}
              </Link>
            </div>
            <div>
              <Button size={"xl"} className={"w-full"} disabled={isLoading}>
                {t("form.submit")}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </LoginLayout>
  );
}

export const getServerSideProps = async ({ req }: { req: IncomingMessage }) => {
  const session = await auth.api.getSession({
    headers: new Headers(req.headers as Record<string, string>),
  });

  if (session !== null) {
    return {
      redirect: {
        statusCode: 302,
        destination: "/app",
      },
    };
  }

  return {
    props: {
      ...(await serverSideTranslations(
        "ua",
        ["login", "plugs"],
        nextI18NextConfig,
      )),
    },
  };
};
