import * as React from "react";
import { Input, type InputProps } from "~/components/ui/input";
import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";

const InputPassword = React.forwardRef<
  HTMLInputElement,
  Omit<InputProps, "type">
>((props, ref) => {
  const [isPassword, setIsPassword] = useState(true);
  return (
    <div className={"relative"}>
      <Input type={isPassword ? "password" : "text"} ref={ref} {...props} />
      <div className={"absolute inset-y-0 right-0 flex pr-4"}>
        <div className={"flex items-center"}>
          {isPassword ? (
            <EyeOff
              className={"cursor-pointer"}
              onClick={() => setIsPassword(false)}
            />
          ) : (
            <Eye
              className={"cursor-pointer"}
              onClick={() => setIsPassword(true)}
            />
          )}
        </div>
      </div>
    </div>
  );
});
InputPassword.displayName = "InputPassword";

export { InputPassword };
